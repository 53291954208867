
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="group" class="col-12">
      <h5 class="mb-3">Group details</h5>
      <div class="card mb-4 overflow-hidden">
        <div class="card-body">
          <div class="row">
            <div class="col-10 col-sm-8">
              <div class="d-flex align-items-center justify-content-start mb-3">
                <div class="me-3">
                  <img @click="showProfileImg(group.logo)" class="cursor-pointer" v-if="group.logo" style="width:3rem;" :src="absoluteUrl(group.logo)" />
                </div> 
                <div class="">
                  <div class="friendly-id mb-1">
                    {{group.friendly_id}}
                  </div>
                  <div class="">
                    <strong class="me-1">Status:</strong>
                    <span v-html="statusBadge(group.status)"> </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-4 text-end">
              <div class="dropdown no-arrow">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </a>
                <ul class="dropdown-menu">
                  <li>
                    <a @click="popupModalShow = true" class="dropdown-item"
                     href="javascript:void(0)" >
                    Update group </a>
                  </li>
                  <li>
                    <a @click.prevent="deleteItem()"
                    class="dropdown-item" href="javascript:void(0)">
                    Delete group </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12">
              <h4 class="lh-base mb-0">{{group.name}}</h4>
              <p v-if="group.description" class="mb-0 mt-2 pt-1 text-muted">{{group.description}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2 gy-2">
        <div class="col-12">
          <members  :groupId="group.id"/>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
  <b-modal v-model="popupModalShow" hide-footer centered
    title="Update group"
    title-class="fs-6" @hide="initializeAll()">
    <group-form v-if="popupModalShow" @closeMe="initializeAll()"
    :editItem="group" @updatedItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import Members from '../members/Members.vue';
import GroupForm from '@/components/forms/GroupForm.vue';

export default {
  name: "group-show",
  components:{
    IsLoading,
    Members,
    GroupForm
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      group: null
    }
  },
  watch: {
    '$route.params.groupId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.group, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/groups/${this.$route.params.groupId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.group = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    deleteItem(){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete an group permanently",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/groups/${this.group.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/groups'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalShow = false;
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

